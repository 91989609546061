"use client";

import { useRouter } from "next/router";
import type {
  Dispatch,
  KeyboardEvent,
  KeyboardEventHandler,
  PropsWithChildren,
  SetStateAction,
  SyntheticEvent,
} from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import type { Hit, SearchResults, SearchState } from "react-instantsearch-core";
import {
  Configure,
  Highlight,
  Index,
  InstantSearch,
  connectHits,
  connectSearchBox,
  connectStateResults,
} from "react-instantsearch-dom";
import { useClickAway } from "react-use";
import title from "title";
import type { DeepReadonly } from "ts-essentials";

import { searchClient } from "../../../../home/lib/algoliasearch";
import type {
  ReferenceSearchSchema,
  TypeSearchSchema,
} from "../../../../home/lib/types";

interface ReferenceHitProperties {
  hits: Hit<TypeSearchSchema>[];
  onClickCallback?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  customRef?: React.MutableRefObject<Map<unknown, unknown>>;
}
interface GuideHitProperties {
  hits: Hit<ReferenceSearchSchema>[];
  onClickCallback?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  customRef?: React.MutableRefObject<Map<unknown, unknown>>;
}

const SearchBox = (
  props: DeepReadonly<{
    currentRefinement: string;
    refine: (argument: string) => void;
    viewResultCallback: (argument: boolean) => void;
    keyDownCallback: KeyboardEventHandler<HTMLInputElement>;
    inputReference: React.RefObject<HTMLInputElement>;
  }>,
) => {
  const {
    currentRefinement,
    viewResultCallback,
    refine,
    inputReference,
    keyDownCallback,
  } = props;

  useEffect(() => {
    if (currentRefinement === "") {
      viewResultCallback(false);
    } else {
      viewResultCallback(true);
    }

    return () => {
      viewResultCallback(false);
    };
  }, [currentRefinement, viewResultCallback]);

  return (
    <form action="" noValidate role="search">
      <label className="relative block w-full sm:w-2/3">
        <span className="sr-only">Search</span>
        <div className="absolute inset-y-0 left-0 flex items-center pl-3">
          <img alt="" src="/img/search-icon.svg" />
        </div>
        <input
          className="block h-10 w-full rounded-full border-0 border-white bg-white pl-10 pr-2 text-xs focus:border-none focus:outline-none focus:ring-0"
          data-testid="input::document-search"
          onChange={(event) => {
            refine(event.currentTarget.value);
          }}
          onKeyDown={keyDownCallback}
          placeholder="Search Documentation"
          ref={inputReference as never}
          value={currentRefinement}
        />
      </label>
    </form>
  );
};

const ResultsComponent = ({
  children,
  setResultsCallback,
  searchResults,
}: Readonly<
  PropsWithChildren<{
    setResultsCallback: Dispatch<SetStateAction<number>>;
    searchResults?: SearchResults;
  }>
>): JSX.Element => {
  if (searchResults) {
    setResultsCallback(searchResults.nbHits);
  }
  if (searchResults && searchResults.nbHits !== 0) {
    return <>{children}</>;
  }
  return <></>;
};

const GuideSearchHitComponent = ({
  hits,
  onClickCallback,
  customRef,
}: GuideHitProperties): JSX.Element => (
  <ol className="space-y-0.5">
    {hits.map((guidePage: Readonly<ReferenceSearchSchema>) => (
      <button
        className="hover:bg-bone focus:bg-bone rounded-rounded flex w-full cursor-pointer items-center space-x-2 px-2.5 py-2 text-xs focus:outline-none"
        data-href={guidePage.url}
        data-testid="search-result::document"
        key={guidePage.objectID}
        onClick={onClickCallback}
        ref={(reference_) => {
          customRef?.current.set(guidePage.objectID, reference_);
        }}
        type="button"
      >
        <div className="flex space-x-3 truncate text-left">
          {guidePage.type === 2 ? (
            <img alt="" src="/img/icon-keyword.svg" />
          ) : (
            <img alt="" src="/img/icon-guide.svg" />
          )}
          <div className="space-y-0.5 truncate ">
            {guidePage.title && (
              <Highlight
                attribute="title"
                hit={guidePage}
                tagName="highlight-reference"
              />
            )}
            <div className="text-xxs truncate opacity-50">
              {guidePage.url.includes("docs/")
                ? title(guidePage.url.split("docs/")[1])
                : title(guidePage.url ?? "")}
            </div>
          </div>
        </div>
      </button>
    ))}
  </ol>
);

const GraphqlSearchHitComponent = ({
  hits,
  onClickCallback,
  customRef,
}: ReferenceHitProperties): JSX.Element => (
  <ol className="space-y-0.5">
    {hits.map((typeSchema: Readonly<TypeSearchSchema>) => (
      <button
        className="hover:bg-bone focus:bg-bone rounded-rounded flex w-full cursor-pointer items-center space-x-2 py-2 px-2.5 text-xs focus:outline-none"
        data-href={`/docs/reference/${typeSchema.section}/${typeSchema.name}`}
        data-testid="search-result::document"
        key={typeSchema.objectID}
        onClick={onClickCallback}
        ref={(reference_) => {
          customRef?.current.set(typeSchema.objectID, reference_);
        }}
        type="button"
      >
        <div className="flex space-x-3 truncate text-left">
          <img alt="" src="/img/icon-reference.svg" />
          <div className="space-y-0.5 truncate">
            {typeSchema.name ? (
              <Highlight
                attribute="name"
                hit={typeSchema}
                tagName="highlight-reference"
              />
            ) : undefined}
            <div className="text-xxs flex space-x-1 truncate opacity-50">
              <span>
                {title((typeSchema.section || "").replaceAll("_", " "))}
              </span>
              {typeSchema.description ?? "" ? (
                <div className="space-x-1 truncate">
                  <span className="font-medium">&middot;</span>
                  <Highlight
                    attribute="description"
                    hit={typeSchema}
                    tagName="highlight-reference"
                  />
                </div>
              ) : undefined}
            </div>
          </div>
        </div>
      </button>
    ))}
  </ol>
);

const CustomHitsBoxGraph = connectHits<
  ReferenceHitProperties,
  TypeSearchSchema
>(GraphqlSearchHitComponent);
const CustomHitsBoxDocuments = connectHits<
  GuideHitProperties,
  ReferenceSearchSchema
>(GuideSearchHitComponent);
const Results = connectStateResults(ResultsComponent);
const CustomSearchBox = connectSearchBox(SearchBox);

export const DocumentSearchContainer = (): JSX.Element => {
  const router = useRouter();
  const [viewResults, setViewResults] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [documentResults, setDocumentResults] = useState(0);
  const [referenceResults, setReferenceResults] = useState(0);
  const [resultIndex, setResultIndex] = useState(0);
  const inputReference = useRef<HTMLInputElement>(null);
  const hitReferences = useRef(new Map());
  useEffect(() => {
    // Set timeout to capture lifecycle delay in mapping refs
    setTimeout(() => {
      const values: (HTMLButtonElement | undefined)[] = Array.from(
        hitReferences.current.values(),
      );
      values.forEach((hitObject) => {
        hitObject?.classList.remove("bg-bone");
      });

      const firstElement = values[resultIndex];
      if (firstElement !== undefined) {
        firstElement?.classList.add("bg-bone");
      }
    }, 10);
  }, [documentResults, referenceResults, resultIndex]);

  const totalResults = documentResults + referenceResults;

  const handleStateChange = useCallback(
    (searchState: DeepReadonly<SearchState>) => {
      // Reset reference map on keystroke
      // eslint-disable-next-line fp/no-mutation
      hitReferences.current = new Map();
      setResultIndex(0);
      setSearchQuery(searchState.query ?? "");
    },
    [],
  );

  const searchState: SearchState = {
    query: searchQuery,
  };

  const handleClick = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>): void => {
      event.preventDefault();
      const { href } = event.currentTarget.dataset;
      void router.push(href ?? "#");
      setSearchQuery("");
      setViewResults(false);
    },
    [router],
  );

  useClickAway(inputReference, (event) => {
    const path = event.composedPath() as Element[];
    if (
      !path.some(
        (element) => element.id === "search" || element.id === "dropdown",
      )
    ) {
      setSearchQuery("");
      setViewResults(false);
    }
  });

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setResultIndex(
        resultIndex + 1 >= Array.from(hitReferences.current.values()).length
          ? resultIndex
          : resultIndex + 1,
      );
    }
    if (event.key === "ArrowUp") {
      event.preventDefault();
      setResultIndex(resultIndex - 1 < 0 ? 0 : resultIndex - 1);
    }
    if (event.key === "Enter") {
      event.preventDefault();
      const values: (HTMLButtonElement | undefined)[] = Array.from(
        hitReferences.current.values(),
      );
      const href = values[resultIndex]?.getAttribute("data-href");
      if (typeof href === "string") {
        void router.push(href);
        setSearchQuery("");
        setViewResults(false);
      }
    }
  };

  useEffect(() => {
    function handleKeyDown(event: { metaKey: boolean; key: string }) {
      if (event.metaKey && event.key === "k") {
        setViewResults(true);
        inputReference.current?.focus();
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <InstantSearch
      indexName="DEV_DOCS"
      onSearchStateChange={handleStateChange}
      searchClient={searchClient}
      searchState={searchState}
    >
      <div className="group relative w-full pr-8 md:pr-0 lg:w-2/3 xl:w-3/5">
        <CustomSearchBox
          inputReference={inputReference as never}
          keyDownCallback={handleKeyPress}
          viewResultCallback={setViewResults}
        />
        {viewResults ? (
          <div
            className="ring-ash fixed left-2 -ml-2.5 mt-2 w-full overflow-y-auto rounded-xl bg-white shadow-lg ring-1 sm:absolute sm:w-5/6"
            style={{ maxHeight: "90vh" }}
            id="dropdown"
          >
            <div className="p-3">
              {totalResults === 0 && (
                <div
                  className="pb-2 text-xs text-gray-500"
                  data-testid="search-result::no-results"
                >
                  No results have been found for{" "}
                  <span className="font-medium">{searchState.query}</span>.
                </div>
              )}
              <h2 className="pb-2 pt-1 text-xs font-medium">Documentation</h2>
              <Index indexName="DEV_DOCS">
                <Results setResultsCallback={setDocumentResults}>
                  <CustomHitsBoxDocuments
                    customRef={hitReferences}
                    onClickCallback={handleClick}
                  />
                </Results>
                <Configure hitsPerPage={5} />
              </Index>

              <h2 className="py-2 text-xs font-medium">API Reference</h2>
              <Index indexName="DEV_GRAPHQL_REFERENCE">
                <Results setResultsCallback={setReferenceResults}>
                  <CustomHitsBoxGraph
                    customRef={hitReferences}
                    onClickCallback={handleClick}
                  />
                </Results>
                <Configure hitsPerPage={10} />
              </Index>
            </div>
          </div>
        ) : undefined}
      </div>
    </InstantSearch>
  );
};
