"use client";

import { CommonAppContext } from "@bay1/data";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import type { PropsWithChildren } from "react";
import { useContext } from "react";

import { AccountDesktopNav } from "../AccountNav";
import { DocumentSearchContainer } from "./DocumentSearchContainer";

export const NavBarLayout = ({
  children,
}: Readonly<PropsWithChildren<unknown>>): JSX.Element => {
  const { activeOrganization } = useContext(CommonAppContext);
  const router = useRouter();

  return (
    <div className="flex items-center justify-between py-3 px-4">
      {router.pathname.includes("/docs") ? (
        <>
          <div className="text-reset mr-2 flex-none">
            <Link
              href={
                activeOrganization?.id
                  ? {
                      pathname: "/",

                      query: {
                        activeOrganizationId: activeOrganization?.id,
                      },
                    }
                  : "/"
              }
              passHref
            >
              <span className="sr-only">Highnote</span>
              <span>
                <Image
                  alt="Highnote"
                  height={40}
                  src="/img/highnote-circle.svg"
                  width={40}
                />
              </span>
            </Link>
          </div>
          <div className="ml-1 flex w-full items-center justify-between rounded">
            <DocumentSearchContainer />
            {children ?? undefined}
            <AccountDesktopNav />
          </div>
        </>
      ) : (
        <>
          <div className="text-reset mr-6 flex items-center">
            <div className="mr-5">
              <Link
                href={
                  activeOrganization?.id
                    ? {
                        pathname: "/",

                        query: {
                          activeOrganizationId: activeOrganization?.id,
                        },
                      }
                    : "/"
                }
                passHref
              >
                <span className="sr-only">Highnote</span>
                <Image
                  alt="Highnote"
                  className=""
                  height={56}
                  src="/img/highnote.svg"
                  width={162}
                />
              </Link>
            </div>
            {children ?? undefined}
          </div>
          <AccountDesktopNav />
        </>
      )}
    </div>
  );
};
